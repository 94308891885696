

import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useLayout } from '../layout-ui/LayoutContext';
import { Menu, Layout, Image } from 'antd';
import MRDIYlogo from '../../../assets/images/MRDIYlogo.png';
import shortLogo from '../../../assets/images/shortLogo.png';
import './Custom.css';
import bannerActive from '../../../assets/sidebar/bannerActive.png';
import bannerInactive from '../../../assets/sidebar/bannerInactive.png';
import userInactive from '../../../assets/sidebar/userInactive.png';
import submoduleActive from '../../../assets/sidebar/submoduleActive.png';
import submoduleInactive from '../../../assets/sidebar/submoduleInactive.png';
import customerActive from '../../../assets/sidebar/customerActive.png';
import customerInactive from '../../../assets/sidebar/customerInactive.png';
import storeActive from '../../../assets/sidebar/storeActive.png';
import storeInactive from '../../../assets/sidebar/storeInactive.png';
import libraryActive from '../../../assets/sidebar/libraryActive.png';
import libraryInactive from '../../../assets/sidebar/libraryInactive.png';
import settingsActive from '../../../assets/sidebar/settingsActive.png';
import settingsInactive from '../../../assets/sidebar/settingsInactive.png';

const { Sider } = Layout;
const { SubMenu } = Menu; // Import SubMenu

const SideBar = () => {
	const layout = useLayout();
	const location = useLocation();
	const { id } = useParams(); 

	const selectedKeys = [location.pathname];
  
  	return (
		<Sider
		trigger={null}
		collapsible
		collapsed={layout.collapsed}
		style={{
			height: '100vh',
			left: 0,
			padding: '20px',
			backgroundColor: '#DCDCDC',
		}}
		width={280}
		>
		<div className="demo-logo-vertical">
			{!layout.collapsed ? (
			<a href="#"><img src={MRDIYlogo} alt="logo" /></a>
			) : (
			<a href="#"><img src={shortLogo} alt="logo" width={'50px'} /></a>
			)}
		</div>
		<Menu
			theme="light"
			style={{
				backgroundColor: '#DCDCDC',
				borderInlineEnd: 'none',
				fontSize: '13px',
			}}
			mode="inline"
			defaultSelectedKeys={selectedKeys}
			selectedKeys={selectedKeys}
		>
		<Menu.Item key="/banner">
			<Image
				width={20}
				src={selectedKeys.includes(selectedKeys[0] === '/banner' ? '/banner' : (selectedKeys[0] === `/banner/${id}` 
				?  `/banner/${id}`: '/banner/add')) ? bannerActive : bannerInactive}
			/>
			<Link className="menu-item-image" to="/banner">Banner Management</Link>
		</Menu.Item>
			
			<SubMenu key="userManagement" 
			  style={{color: 'red'}}
			title={
				<div style={{ color: 'black'}}>
					<Image
						width={15}  
						src={userInactive}  
						alt="User Management"
						style={{ marginRight: 8 }}  
					/>
					<span style={{ marginLeft: 15, fontWeight: '500', color: 'black' }}>User Management</span>
				</div>
			  }
			>
				<Menu.Item key="/user">
					<Image
						width={5}
						src={selectedKeys.includes(selectedKeys[0] === '/user' ? '/user' : (selectedKeys[0] === `/user/${id}` 
						?  `/user/${id}`: '/user/add')) ? submoduleActive : submoduleInactive}
					/>
					<Link className="menu-item-image" to="/user">User List</Link>
				</Menu.Item>
				{/* <Menu.Item key="/roles"
				//  icon={<UploadOutlined />}
				>
					<Link to="/roles">Roles and Permission</Link>
				</Menu.Item> */}
			</SubMenu>

			<Menu.Item key="/customer">
				<Image
					width={15}
					src={selectedKeys.includes(selectedKeys[0] === '/customer' ? '/customer' : (selectedKeys[0] === `/customer/${id}` 
					?  `/customer/${id}`: '/customer/add')) ? customerActive : customerInactive}
				/>
				<Link className="menu-item-image" to="/customer">Customer Management</Link>
			</Menu.Item>

			<Menu.Item key="/store">
				<Image
					width={15}
					src={selectedKeys.includes(selectedKeys[0] === '/store' ? '/store' : (selectedKeys[0] === `/store/${id}` 
					?  `/store/${id}`: '/store/add')) ? storeActive : storeInactive}
				/>
				<Link className="menu-item-image" to="/store">Store Management</Link>
			</Menu.Item>

			<Menu.Item key="/library">
				<Image
					width={15}
					src={selectedKeys.includes(selectedKeys[0] === '/library' ? '/library' : (selectedKeys[0] === `/library/${id}` 
					?  `/library/${id}`: '/library/add')) ? libraryActive : libraryInactive}
				/>
				<Link className="menu-item-image" to="/library">Library</Link>
			</Menu.Item>

			<Menu.Item key="/account" >
				<Image
					width={15}
					src={selectedKeys.includes(selectedKeys[0] === '/account' ? '/account' : (selectedKeys[0] === `/account/${id}` 
					?  `/account/${id}`: '/account/add')) ? settingsActive : settingsInactive}
				/>
				<Link className="menu-item-image" to="/account">Account Settings</Link>
			</Menu.Item>
		</Menu>
		</Sider>
	);
};

export default SideBar;
