    import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { APIurl } from '../../utils/apiUrl';

// Define the shape of a banner
interface Banner {
    id: number;
    description?: string;
    image?: string;
    title?: string;
    startDate?: string;
    endDate?: string;
    noExpiration? : any;
    status?: any;
    transactionStatus?: any;
    category?: any;
    [key: string]: any; // Add other banner properties here
}

// Define the shape of the initial state
interface BannerState {
    banners: Banner[];
    bannerstoDelete: React.Key[];
    searchText: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string;
    transactionStatus: string;
    selectedRow: number;
}

const initialState: BannerState = {
    banners: [],
    bannerstoDelete: [],
    searchText: '',
    status: 'idle',
    transactionStatus: '',
    error: '',
    selectedRow: 0,
}

// Create the async thunk
export const fetchBanners = createAsyncThunk('banner/fetchBanners', async () => {
    const response = await axios.get(`${APIurl}/api/banners`);
    return response.data;
  });

export const fetchBannerById = createAsyncThunk(
    'banner/fetchBannerById',
    async (id: number) => {
        const response = await axios.get(`${APIurl}/api/banners/${id}`);
        return response.data;
    }
);

export const updateBannerById = createAsyncThunk(
    'banner/updateBannerById',
    async (banner: Banner) => {
        console.log(banner)
        const response = await axios.put(`${APIurl}/api/banners/${banner.id}`, banner, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data; // Assuming the API returns the updated banner object
    }
);

export const deleteMany = createAsyncThunk(
    'banner/deleteMany',
    async (banner: React.Key[]) => {
        const response = await axios.post(`${APIurl}/api/banners/delete-many`, banner);
        return response.data; // Assuming the API returns the updated banner object
    }
);

export const createBanner = createAsyncThunk(
    'banner/createBanner',
    async (banner: Banner) => {
        const response = await axios.post(`${APIurl}/api/banners`, banner)
        return response.data; // Assuming the API returns the updated banner object
        //     {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // });
    }
);

export const deleteBannerById = createAsyncThunk(
    'banner/deleteBannerById',
    async (id: number) => {
        const response = await axios.delete(`${APIurl}/api/banners/${id}`);
        return response.data; // Return the ID of the deleted banner
    }
);

const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        setSearchText(state, action: PayloadAction<string>) {
            state.searchText = action.payload;
        },
        setBannerListsToDelete(state, action: PayloadAction<React.Key[]>) {
            state.bannerstoDelete = action.payload;
        },
        setDeleteBannerLists(state, action: PayloadAction<React.Key[]>) {
            const idsToDelete = action.payload;
            state.banners = state.banners.filter(banner => !idsToDelete.includes(banner.id));
        },
        setSelectedRow(state, action: PayloadAction<number>) {
            state.selectedRow = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBanners.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBanners.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.banners = action.payload;
            })
            .addCase(fetchBanners.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ?? 'Failed to fetch banners';
            })
            .addCase(createBanner.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(createBanner.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.banners.push(action.payload);
            })
            .addCase(createBanner.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to create banners, please contact your administrator';
            })
            .addCase(fetchBannerById.fulfilled, (state, action) => {
                state.banners = action.payload;
            })
            .addCase(updateBannerById.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(updateBannerById.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                 state.banners = action.payload;
                 state.error = '';
            })
            .addCase(updateBannerById.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to update banner, please contact your administrator';
            })
            .addCase(deleteMany.pending, (state) => {
            state.transactionStatus = 'loading';
            })
            .addCase(deleteMany.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.banners = action.payload;
                state.error = '';
            })
            .addCase(deleteMany.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to delete banners, please contact your administrator';
            })
            .addCase(deleteBannerById.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(deleteBannerById.fulfilled, (state, action) => {
                state.banners = action.payload;
                state.error = '';
                state.transactionStatus = 'succeeded';
            })
            .addCase(deleteBannerById.rejected, (state, action) => {
                state.error = action.error.message ?? 'Failed to delete banner, please contact your administrator';
                state.transactionStatus = 'failed';
            });
    },

});

export const { 
    setSearchText,
    setBannerListsToDelete,
    setDeleteBannerLists,
    setSelectedRow
} = bannerSlice.actions;

export const selectBanners = (state: RootState) => state.storeBanner.banners;
export const selectSearchText = (state: RootState) => state.storeBanner.searchText;

export const selectFilteredBanners = createSelector(
    [selectBanners, selectSearchText],
    (banners, searchText) => {
        if (!searchText) {
            return banners;
        }
        return banners.filter(banner =>
            (banner.description?.toLowerCase().includes(searchText.toLowerCase()) || 
             banner.title?.toLowerCase().includes(searchText.toLowerCase()) || 
             banner.startDate?.toLowerCase().includes(searchText.toLowerCase()) || 
             banner.endDate?.toLowerCase().includes(searchText.toLowerCase()))
        );
    }
);

export default bannerSlice.reducer;
