import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import Buttons from "../../components/Buttons/Buttons";
import TextBox from "../../components/common/LoginForms/TextBox";
import './Custom.css';
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { PlusCircleFilled, DeleteFilled } from '@ant-design/icons'; 
import DeleteButtons from "../../components/Buttons/DeleteButtons";
import MainTable from "../../components/Tables/Table";
import { setSearchText, selectFilteredCustomers, deleteMany,  setCustomerListsToDelete, fetchCustomers} from "../../redux/customer/reducer";
import { Toaster } from "../../components/common/Toaster/Toaster";
import { useCustomerColumns } from "./columns";
import { useEffect } from "react";
import { Popconfirm } from "antd";

const Customer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const columns = useCustomerColumns();
    const Customers = useSelector((state: RootState) => state.storeCustomer.customers)|| [];
    const toDeleteCustomerLists = useSelector((state: RootState) => state.storeCustomer.customerstoDelete) || [];
    const filteredCustomers = useSelector(selectFilteredCustomers) || [];
    const searchText = useSelector((state: RootState) => state.storeCustomer.searchText);
    const transactionStatus = useSelector((state: RootState) => state.storeCustomer.transactionStatus);
    const selectedRow = useSelector(
        (state: RootState) => state.storeCustomer.selectedRow
      );
    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchText(event.target.value));
    };

    useEffect(() => {
        dispatch(setCustomerListsToDelete([]));
    }, []);


    const handleDeleteCustomerLists = async () => {
        await dispatch(deleteMany(toDeleteCustomerLists));
        await dispatch(setCustomerListsToDelete([]));
        await Toaster('Successfully Deleted Customers', 'success');
    };

    const handleAddCustomer = () => {
        navigate(`/customer/add`, { replace: true });
    };
    
    const data = searchText ? filteredCustomers.map(item => ({
        ...item,
        key: item.id,
    })) : 
        Customers.map(item => ({
        ...item,
        key: item.id,
    }))
    ;

    useEffect(() => {
        dispatch(fetchCustomers());
    }, [dispatch]);

    const rowClassName = (record:any) => {
        return record.key === selectedRow ? 'selected-row' : '';
      };

    return (
        <>
            <div>
                <h1>Customer Management</h1>
                <div className="customer-row">
                 <div className="customer-row" style={{marginLeft: '-7px'}}> 
                        {/* <Buttons title="Add Customer" icon={<PlusCircleFilled />} onClick={handleAddCustomer} /> */}
                        <Popconfirm
                        title="Delete Message"
                        description="Are you sure you want to delete selected items?"
                        onConfirm={() => handleDeleteCustomerLists()}
                        okText="Delete"
                        >
                        <DeleteButtons title="Delete Customer" icon={<DeleteFilled />} disabled={toDeleteCustomerLists.length < 1} loading={transactionStatus === 'loading'} />
                        </Popconfirm>
                    </div>
                    <div style={{ marginLeft: 'auto', width:' 400px' }}> 
                        <TextBox 
                            placeholder="Search" 
                            prefix={<SearchOutlined />}
                            onChange={handleSearchTextChange}
                        />
                    </div>
                </div>

                <div className="table-row">
                    <MainTable 
                         columns={columns} data={data} rowClassName={rowClassName}                                     
                    />
                </div>
            </div>
        </>
    );
}

export default Customer;
